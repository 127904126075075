import React from 'react';

const Certification = ({ name, logo, link }) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center">
            <img src={logo} alt={name} className="w-20 h-20 md:w-24 md:h-24 mx-auto" />
            <p className="mt-2 text-white text-sm md:text-base text-center">{name}</p>
        </a>
    );
};

export default Certification;
