import React from 'react';

const projects = [
    {
        title: "Chicago Crimes",
        description: "Developed a React app using Google Cloud Functions, BigQuery, and Firebase to display crime data on a map.",
        github: "https://github.com/abmnq/chicago-crimes-react",
        demo: "https://cozy-semifreddo-e5f6bc.netlify.app/"
    },
    {
        title: "Global Jags",
        description: "Created an app to geolocate and display images on a map using Google Cloud Functions and BigQuery.",
        github: "https://github.com/abmnq/globaljags",
        demo: "https://global-jags.demo.com"
    },
    {
        title: "Malware Scanner",
        description: "Built a Google Cloud Function to classify and report on malware files.",
        github: "https://github.com/yourusername/malware-scanner",
        demo: "https://malware-scanner.demo.com"
    },
    {
        title: "Tutor Application",
        description: "Developed a React app with Firestore and Google Cloud Functions to manage tutor bookings and send email confirmations via SendGrid.",
        github: "https://github.com/abmnq/tutor101",
        demo: "https://leilanwf.com/"
    },
    {
        title: "Password Manager Server",
        description: "Set up a Passbolt server using Docker, Cloudflare, and an SMTP server for secure password management.",
        
    },
    {
        title: "Travel Deals",
        description: "Created an application to notify users of travel deals using Firebase, SendGrid, Twilio, and Google Cloud Functions.",
        github: "https://github.com/abmnq/traveldeals",
        
    },
    {
        title: "Ad-blocking and Privacy Protection",
        description: "Used a Raspberry Pi and AdGuard to set up DNS filtering for network control and privacy.",
        
    },
    {
        title: "Python-based Bot",
        description: "Developed and deployed a bot using Docker and AWS ECS.",
        
    },
    {
        title: "Gene Sequence Analysis Tool",
        description: "Created a Next.js tool for rapid gene sequence analysis.",
        github: "https://github.com/abmnq/Gene-Seq",
        demo: "https://www.gseq.org"
    }
];

const Projects = () => {
    return (
        <section id="projects" className="p-8 bg-dark-900 text-white">
            <div className="container mx-auto">
                <h2 className="text-4xl font-bold mb-4">My Recent Works</h2>
                <p className="text-lg mb-8">Here are a few projects I’ve worked on recently.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {projects.map((project, index) => (
                        <div key={index} className="bg-dark-800 p-6 rounded-lg shadow-lg">
                            <h3 className="text-2xl font-bold mb-2">{project.title}</h3>
                            <p className="text-lg mb-4">{project.description}</p>
                            <a href={project.github} className="mr-4 text-blue-400">GitHub</a>
                            <a href={project.demo} className="text-blue-400">Demo</a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Projects;
