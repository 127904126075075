import React from 'react';
import Certification from './Certification';

const certifications = [
    {
        name: "CompTIA Security+",
        logo: "https://images.credly.com/images/131de2f5-03f5-40a7-bcce-f9ae49e3979c/twitter_thumb_201604_CompTIA_Security_2B.png",
        link: "https://www.credly.com/badges/98d8f746-dc28-4843-a088-18dc2f88e156/linked_in_profile"
    },
    {
        name: "AWS Certified Solutions Architect – Associate",
        logo: "https://d1.awsstatic.com/training-and-certification/certification-badges/AWS-Certified-Solutions-Architect-Associate_badge.3419559c682629072f1eb968d59dea0741772c0f.png",
        link: "https://www.credly.com/badges/9a06d154-1f85-4331-b372-a23a040c8ffa/linked_in_profile"
    },
    {
        name: "AZ-104: Azure Administrator Associate",
        logo: "https://images.credly.com/images/336eebfc-0ac3-4553-9a67-b402f491f185/azure-administrator-associate-600x600.png",
        link: "https://learn.microsoft.com/api/credentials/share/en-us/AhmedMohammed-5673/80660D877A0BA4A4?sharingId=DD1AFE024D591993"
    },
    {
        name: "AWS Certified Developer – Associate",
        logo: "https://d1.awsstatic.com/training-and-certification/certification-badges/AWS-Certified-Developer-Associate_badge.5c083fa855fe82c1cf2d0c8b883c265ec72a17c0.png",
        link: "https://www.credly.com/badges/ec5af99d-0869-4ada-95c9-7458480d46be/linked_in_profile"
    },
    {
        name: "AWS Certified Cloud Practitioner",
        logo: "https://d1.awsstatic.com/training-and-certification/certification-badges/AWS-Certified-Cloud-Practitioner_badge.634f8a21af2e0e956ed8905a72366146ba22b74c.png",
        link: "https://www.credly.com/badges/826b59fe-ee18-46fe-a7de-5151d1fbf910/linked_in_profile"
    },
    {
        name: "MS-900 Microsoft Fundamentals",
        logo: "https://intunedin.net/wp-content/uploads/2020/05/image.png",
        link: "https://learn.microsoft.com/api/credentials/share/en-us/AhmedMohammed-5673/BDBEFD841FD8CF5C?sharingId"
    },
    {
        name: "AZ-900: Azure Fundamentals",
        logo: "https://miro.medium.com/v2/resize:fit:600/1*xaBJMux-ZpeRnyH3RDQXYA.png",
        link: "https://learn.microsoft.com/api/credentials/share/en-us/AhmedMohammed-5673/CBCDCDFF16D198D1?sharingId"
    },
    {
        name: "CompTIA A+",
        logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGzgYRUhTs--yYJE8Yre0caNW24qFhL_SEOg&s",
        link: "https://verify.comptia.org"
    }
];

const Home = () => {
    return (
        <section id="home" className="min-h-screen flex flex-col items-center justify-center bg-dark-900 text-white">
            <div className="text-center px-4 py-8">
                <h2 className="text-3xl font-bold pt-6 mt-6">Hi There! <span role="img" aria-label="wave">👋</span></h2>
                <h1 className="text-6xl font-bold mt-5">I'm Ahmed Mohammed</h1>
                <h3 className="text-3xl mt-4">Cyber Security Specialist & Developer</h3>
                <div className="mt-8">
                    <h4 className="text-3xl font-bold">Certifications</h4>
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 mt-4">
                        {certifications.map((cert, index) => (
                            <Certification key={index} {...cert} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;