import React from 'react';

const Header = () => {
    return (
        <header className="bg-dark-800 text-white p-4 fixed w-full top-0 z-50">
            <div className="container mx-auto flex justify-between items-center">
                <h1 className="text-3xl font-bold">AM</h1>
                <nav>
                    <a href="#home" className="mr-4">Home</a>
                    <a href="#about" className="mr-4">About</a>
                    <a href="#projects" className="mr-4">Projects</a>
                    <a href="#resume" className="mr-4">Resume</a>
                </nav>
            </div>
        </header>
    );
};

export default Header;
