import React from 'react';

const About = () => {
    return (
        <section id="about" className="p-8 bg-dark-800 text-white">
            <div className="container mx-auto">
                <h2 className="text-4xl font-bold mb-4">About Me</h2>
                <p className="text-lg">Based in Fishers, IN, I am a recent graduate from Purdue School of Engineering and Technology with a passion for cyber security and web development. I have extensive experience in digital marketing, technology instruction, and security analysis.</p>
            </div>
        </section>
    );
};

export default About;
