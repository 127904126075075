import React from 'react';

const Resume = () => {
    return (
        <section id="resume" className="p-8 bg-dark-800 text-white">
            <div className="container mx-auto">
                <h2 className="text-4xl font-bold mb-4">Resume</h2>
                <a href="https://docs.google.com/document/d/1pYn-5Cb98khEOLX_E5FTnGuATz9A37tVwb5RaV_Z7eg/edit?usp=sharing" className="bg-blue-500 text-white py-2 px-4 rounded-lg">Download CV</a>
                <div className="mt-8">
                    <h3 className="text-2xl font-bold">Work Experience</h3>
                    <div className="mt-4">
                        <h4 className="text-xl font-semibold">Web & Digital Marketing Specialist</h4>
                        <p>Basim Najeeb Real Estate, Feb 2019 – Present</p>
                        <ul className="list-disc list-inside">
                            <li>Developed and managed the company website and Google page, enhancing online presence and client engagement.</li>
                            <li>Handled social media and digital marketing efforts, significantly boosting brand visibility and reach.</li>
                            <li>Created compelling marketing materials, including property photos and videos, to effectively showcase listings.</li>
                        </ul>
                    </div>
                    <div className="mt-4">
                        <h4 className="text-xl font-semibold">Technology Instructor</h4>
                        <p>Heritage Place Senior Center, Aug 2022 – Dec 2022</p>
                        <ul className="list-disc list-inside">
                            <li>Conducted regular classes on computer basics, enhancing digital literacy among senior citizens.</li>
                            <li>Developed and implemented a curriculum focusing on internet safety and digital communication tools.</li>
                            <li>Personalized learning methods to suit individual learner needs, improving technological confidence.</li>
                        </ul>
                    </div>
                    <div className="mt-4">
                        <h4 className="text-xl font-semibold">Security Analyst Intern</h4>
                        <p>Navient, May 2022 – Aug 2022</p>
                        <ul className="list-disc list-inside">
                            <li>Conducted security incident investigations and false positive resolutions using Nessus and ServiceNow.</li>
                            <li>Collaborated on security policies and governance, ensuring organizational alignment with regulations.</li>
                            <li>Analyzed SIEM logs to identify and assess potential threats, facilitating effective risk mitigation strategies.</li>
                        </ul>
                    </div>
                </div>
                <div className="mt-8">
                    <h3 className="text-2xl font-bold">Education</h3>
                    <div className="mt-4">
                        <h4 className="text-xl font-semibold">Purdue School of Engineering and Technology, Indianapolis</h4>
                        <p>Master of Science in Cyber Security and Trusted Systems, Graduated: May 2024</p>
                        <p>Bachelor of Science in Computer and Information Technology, Graduated: May 2023</p>
                        <p>Concentration: Information Security</p>
                    </div>
                </div>
                <div className="mt-8">
                    <h3 className="text-2xl font-bold">Certifications</h3>
                    <ul className="list-disc list-inside">
                        <li>CompTIA Security+</li>
                        <li>AWS Certified Solutions Architect – Associate</li>
                        <li>AZ-104: Azure Administrator Associate</li>
                        <li>AWS Certified Developer – Associate</li>
                        <li>AWS Certified Cloud Practitioner</li>
                        <li>MS-900 Microsoft Fundamentals</li>
                        <li>AZ-900: Azure Fundamentals</li>
                        <li>CompTIA A+</li>
                    </ul>
                </div>
                <div className="mt-8">
                    <h3 className="text-2xl font-bold">Skills</h3>
                    <ul className="list-disc list-inside">
                        <li>Programming: JavaScript (React, Node.js), HTML, CSS, Shell (Unix, Linux)</li>
                        <li>Systems: Windows, Linux, macOS</li>
                        <li>Cyber Security: Risk Assessment, Cryptography, Wireshark, Nmap, Nessus, SIEM, Firewalls, IPS/IDS</li>
                        <li>Cloud: AWS, Microsoft Azure, Google Cloud Platform (GCP), Firebase, Docker, Netlify</li>
                        <li>Tools & Technologies: Splunk, ServiceNow, SendGrid, Twilio</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Resume;
